<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-row>
        <no-content
          v-if="showCabinet"
          route-name="CommerceDocuments"
          buttonName="В кабинет"
          title="Перейдите в кабинет, для заполнения данных"
        ></no-content>
        <v-col class="text-left" cols="12">
          <v-card
            style="max-width: 900px"
            v-if="doc64 && !documentStatus"
            class="doc-menu-card pa-0"
          >
            <object>
              <embed
                :src="'data:application/pdf;base64,' + doc64"
                height="600"
                width="100%"
              />
            </object>
            <div class="sms-action pa-4">
              <v-btn
                v-if="showCode"
                color="primary"
                tile
                text
                small
                class="px-0"
                :loading="codeLoading"
                @click="sendCode"
                >Отправить смс снова</v-btn
              >
              <v-btn
                v-else
                color="primary"
                :loading="codeLoading"
                @click="sendCode"
                >Подписать</v-btn
              >

              <v-form v-if="showCode" v-model="valid" ref="form">
                <v-text-field
                  v-model="smsCode"
                  name="inputSmsCode"
                  label="Введите код"
                  :rules="requiredRules"
                ></v-text-field>
                <v-subheader class="pl-0">Цена</v-subheader>
                <v-slider
                  v-model="price"
                  min="99"
                  max="280"
                  :rules="priceRules"
                  :label="price + ' руб.'"
                >
                </v-slider>
                <v-subheader class="px-0"
                  >Ознакомительный фрагмент (минимум 40 тыс.
                  символов)</v-subheader
                >
                <v-select
                  v-if="chapters_public.length > 0"
                  v-model="chpid"
                  :items="chapters_public"
                  label="Глава с которой начнется платный контент"
                  item-text="title"
                  item-value="id"
                ></v-select>
                <v-btn
                  color="primary"
                  :loading="codeLoading"
                  @click="sendVerifyCode"
                  >Подтвердить</v-btn
                >
              </v-form>
            </div>
          </v-card>
          <v-card
            v-if="documentStatus == 2 && history && history.agreement"
            class="doc-menu-card"
          >
            <v-card-title>{{ title }} </v-card-title>
            <v-card-text>
              <router-link
                v-if="history.agreement"
                class="d-flex font-weight-bold mb-4"
                :to="{
                  name: 'CommerceDocumentHistory',
                  params: { id: history.agreement.id, type: 'ag' },
                }"
                >{{ history.agreement.name }}</router-link
              >
              <!-- v-if="documentStatus == 2 && history && history.agreement" -->
              <div
                class="appendixes"
                v-if="history && history.appendixes.length > 0"
              >
                <router-link
                  v-for="(appendix, index) in history.appendixes"
                  class="d-flex ml-8 mb-4"
                  :key="index"
                  :to="{
                    name: 'CommerceDocumentHistory',
                    params: { id: appendix.id, type: 'ap' },
                  }"
                >
                  {{ appendix.name }}
                </router-link>
              </div>
            </v-card-text>
            <!-- <v-card-actions class="mt-auto px-4 pb-4 pt-0"> </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    slug: "",
    valid: true,
    smsCode: "",
    codeLoading: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],

    bookId: null,
    priceRules: [
      //   v => !!v || "Поле обязательно",
      (v) => v >= 99 || "Цена должна быть от 99 руб. до 280 руб.",
      (v) => v <= 280 || "Цена должна быть от 99 руб. до 280 руб.",
      // (v) => !v || /^[\d]*$/.test(v) || "Только цифры",
      // (v) =>
      //   (!!v === v >= 60) === v <= 80 ||
      //   "Цена должна быть от 50 руб. до 150 руб.",
    ],
    price: 0, //Цена
    chpid: "", //Номер главы с которой будет платный контент
    showCabinet: false,
    complete: false, //Будем ли мы завершать книгу
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    checkRedirect(statusDoc, typeDoc) {
      //проверка главного заявления
      let routeName = "";
      let params = {};
      if (statusDoc.statement != 2) {
        routeName = "StatementDocument";
      } else if (statusDoc.payout != 2) {
        routeName = "CommerceDocuments";
      } else if (+typeDoc == 1 && statusDoc.sub != 2) {
        routeName = "ContractsDetail";
        params = { slug: "sub" };
      } else if (+typeDoc == 2 && statusDoc.sell != 2) {
        routeName = "ContractsDetail";
        params = { slug: "sell" };
      }
      this.$router.push({
        name: routeName,
        params: params,
      });
    },
    getAppendix() {
      let app = this;
      this.$store
        .dispatch("documents/getAppendix", {
          bookId: app.bookId,
          complete: app.complete,
        })
        .then(() => {
          app.codeLoading = false;
        })
        .catch((error) => {
          if (error.response.data.showCabinet) {
            app.showCabinet = true;

            //Проверка куда будем переадресовыввать
            this.checkRedirect(
              error.response.data.documentsStatus,
              error.response.data.typeDoc
            );
          }
          app.codeLoading = false;
        });
    },
    sendCode() {
      let app = this;
      this.$store
        .dispatch("documents/sendCodeAppendix", {
          docId: app.docId,
        })
        .then(() => {
          app.codeLoading = false;
        })
        .catch(() => {
          app.codeLoading = false;
        });
    },
    sendVerifyCode() {
      if (!this.validate()) return;
      let app = this;
      this.$store
        .dispatch("documents/sendVerifyCodeAppendix", {
          smsCode: app.smsCode,
          docId: app.docId,
          chpid: app.chpid,
          price: app.price,
        })
        .then(() => {
          app.codeLoading = false;
          this.$router.push({
            name: "CabinetBookEdit",
            params: { id: this.book.id },
            query: { p_dialog: 1 },
          });
        })
        .catch(() => {
          app.codeLoading = false;
        });
    },
    updateData() {
      if (this.book) {
        this.chpid = this.book.chpid !== null ? +this.book.chpid : "";
        this.price = this.book.price !== null ? +this.book.price : 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      doc64: "documents/doc64",
      docId: "documents/docId",
      documentStatus: "documents/documentStatus",
      showCode: "documents/showCode",
      history: "documents/history",
      book: "cabinet_books/book",
      chapters_public: "cabinet_chapters/chapters_public",
    }),
    title() {
      let title = "Документы";
      if (this.slug == "sell") {
        title = "Документы продажи";
      } else if (this.slug == "sub") {
        title = "Документы подписки";
      }

      return title;
    },
    type() {
      let type = 0;
      if (this.slug == "sell") {
        type = 2;
      } else if (this.slug == "sub") {
        type = 1;
      }
      return type;
    },
  },
  mounted() {
    this.bookId = this.$route.params.id;
    this.complete = this.$route.query.complete ? true : false;
    if (this.bookId) {
      this.getAppendix();
    }
  },
  watch: {
    book() {
      if (this.book) {
        this.updateData();
      }
    },
  },
};
</script>
